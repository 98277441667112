/* eslint-disable */
import React from 'react'
import './styles.scss'
import DashboardItem from '../../components/items/dashboardItem'
import home from '../../images/home.png'
import admin from '../../images/admin-with-cogwheels.png'
import user from '../../images/user.png'
import bike from '../../images/bike.png'
import station from '../../images/station.png'
import dock from '../../images/dock.png'
import city from '../../images/city.png'
import settingsIcon from '../../images/settings.png'
import referralIcon from '../../images/referral.png'
import bookingsIcon from '../../images/bookingsIcon.png'
import couponIcon from '../../images/coupon.png'
import rentals from '../../images/rentals.png'
import transaction from '../../images/transaction.png'
import { ASC } from '../../constants/const'
import ClientContainer from '../clientContainer'
import BikeContainer from '../bikeContainer'
import TransactionContainer from '../transactionContainer'
import StationContainer from '../stationContainer'
import Header from '../../components/header'
import { connect } from 'react-redux'
import { getUsers } from '../../actions/users'
import { getStations, getStationsOnMap } from '../../actions/stations'
import { strings } from '../../I18n'
import { getPayment } from '../../actions/payment'
import { getBikeStatus, getBikeTypes, getVehicle } from '../../actions/vehicle'
import { getLocks } from '../../actions/locks'
import { getDocks, getDockStatuses } from '../../actions/docks'
import DockContainer from '../dockContainer'
import { cityList, getCities, selectCity } from '../../actions/cities'
import CityContainer from '../cityContainer'
import { getRentals } from '../../actions/rentals'
import {
  setCurrentPageValue,
  setFilterValue,
  setOrder,
  setOrderBy,
  setSearchValue,
} from '../../actions/screen'
import RentalsContainer from '../rentalsContainer'
import { clearErrorMessage, getAdminDetails } from '../../actions/auth'
import { filterUrl, isSuperAdmin, isSuperAdminOrCityAdmin } from '../../utils/utils'
import { useShowToast } from '../../hooks'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import * as routes from '../../constants/routes'
import MapContainerV2 from '../mapContainerV2'
import BikeHistoryContainer from '../bikeHistoryContainer'
import CouponsScreen from '../couponsContainer'
import ReferralsScreen from '../referralsContainer'
import SettingsContainer from '../SettingsContainer'
import BookingScreen from '../bookingContainer'
import NotFound from '../NotFoundContainer'
import MFAContainer from '../MFAContainer'
import { getCurrentVersion } from '../../configs/localVCS'
import UserLedger from '../userLedger'
import PricingPlanEditor from '../pricingPlanEditor'
import UserManagementScreen from '../userManagementContainer'
import CouponUserScreen from '../couponUserContainer'
import { NotificationsContainer } from '../notificationsContainer/NotificationsContainer'
import { AssetsMenu } from './AssetsMenu'
import { AssetProjects } from '../asset/assetProjects/AssetProjects'
import { AssetAssets } from '../asset/assetAssets/AssetAssets'
import { useIsAdminAsset } from '../../hooks/user-hooks'
import { AssetBookings } from '../asset/assetBookings/AssetBookings'
import RuleIcon from '@mui/icons-material/Rule'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import { RentalRulesContainer } from '../rentalRules/RentalRulesContainer'
import { RentalsGallery } from '../rentalsGallery/RentalsGallery'
import { PricingPlanDomains } from '../pricingPlanDomains/PricingPlanDomains'
import { ServiceAccountsContainer } from '../serviceAccounts/ServiceAccountsContainer'
import { IntrosContainer } from '../intros/IntrosContainer'
import { WarningsContainer } from '../warnings/WarningsContainer'

function DashboardScreen(props) {
  const {
    errorMessage,
    clearErrorMessage,
    removeUserSuccess,
    citiesList,
    pageSize,
    setSearchValue,
    setFilterValue,
    setCurrentPageValue,
    searchValue,
    filterValue,
    currentPageValue,
    setOrder,
    setOrderBy,
    order,
    orderBy,
    savedCity,
    typeFilter,
    stationTypeFilter,
    stationStateFilter,
    bikeStateFilter,
    adminDetails,
    getDockStatuses,
  } = props

  const showToast = useShowToast()
  const [searchTimeout, setSearchTimeout] = React.useState(0)
  const isAdminAsset = useIsAdminAsset()

  const navigate = useNavigate()
  const location = useLocation()

  const orderProps = {
    order,
    setOrder,
    orderBy,
    setOrderBy,
  }

  const filterProps = {
    filterValue,
    setFilterValue,
  }

  const orderValue = React.useMemo(
    () => (orderBy === '' ? '' : `${orderBy},${order}`),
    [orderBy, order]
  )

  const currentfilterValue = React.useMemo(
    () => (!!filterValue ? filterUrl(filterValue, props.citiesList) : ''),
    [filterValue, props]
  )

  React.useEffect(() => {
    if (errorMessage !== null) {
      showToast(errorMessage, 'error')
      clearErrorMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, clearErrorMessage])

  React.useEffect(() => {
    if (removeUserSuccess !== null) {
      props.getUsers(currentPageValue, searchValue, orderValue, currentfilterValue, pageSize)
      // showToast(strings('message.removeUserSuccess'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeUserSuccess])

  const loader = () => {
    switch (location.pathname) {
      case routes.DASHBOARD_SCREEN:
        props.getBikeStatus()
        break
      case routes.DASHBOARD_USERS:
        props.getUsers(
          currentPageValue,
          searchValue,
          orderValue,
          // will remove this to defaul
          currentfilterValue +
            (savedCity
              ? '&' + savedCity
              : adminDetails?.cityId
              ? '&cityId=' + adminDetails.cityId
              : ''),
          pageSize
        )
        break
      case routes.DASHBOARD_TRANSACTIONS:
        props.getPayment(
          currentPageValue,
          searchValue,
          orderValue,
          currentfilterValue + (savedCity ? '&' + savedCity : ''),
          pageSize
        )
        break
      case routes.DASHBOARD_BIKES:
      case routes.DASHBOARD_STATIONS:
        break
      case routes.DASHBOARD_DOCKS:
        props.getDocks(
          currentPageValue,
          searchValue,
          orderValue,
          currentfilterValue + (savedCity ? '&' + savedCity : ''),
          pageSize
        )
        getDockStatuses()
        break
      case routes.DASHBOARD_CITIES:
        props.getCities(currentPageValue, searchValue, orderValue, currentfilterValue, pageSize)
        break
      case routes.DASHBOARD_RENTALS:
        break
      default:
        checkParamForNewTab()
        break
    }
  }

  const checkParamForNewTab = () => {
    let path = location.pathname
    let id = path.split('/').splice(3, 1)

    path = path.replace(id, '')

    switch (path) {
      case '/dashboard/user/':
        props.getUsers(
          currentPageValue,
          searchValue,
          orderValue,
          currentfilterValue + (id ? `&userId=${id[0]}` : '') + (savedCity ? '&' + savedCity : ''),
          pageSize
        )
        break
      case '/dashboard/bike/':
        props.getBikeTypes()
        break
      case '/dashboard/transaction/':
        searchValue &&
          props.getPayment(
            currentPageValue,
            searchValue,
            orderValue,
            savedCity ? '&' + savedCity : '',
            pageSize
          )
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (currentPageValue === 0) {
      setSearchTimeout(setTimeout(loader, 500))
    } else {
      setCurrentPageValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCity, pageSize, filterValue, searchValue, adminDetails])

  React.useEffect(() => {
    setSearchTimeout(setTimeout(loader, 500))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, currentPageValue])

  const refreshProps = () => {
    setSearchValue('')
    setFilterValue(null)
    setOrder(ASC)
    setOrderBy('')
    setCurrentPageValue(0)
  }

  const pressOnDashboard = () => {
    refreshProps()
    props.getStationsOnMap(
      typeFilter,
      stationTypeFilter,
      stationStateFilter,
      bikeStateFilter,
      savedCity
    )
    navigate(routes.DASHBOARD_SCREEN)
  }

  const pressOnUserMenu = () => {
    refreshProps()
    // will remove this to defaul
    props.getUsers(
      0,
      '',
      '',
      savedCity ? '&' + savedCity : adminDetails?.cityId ? '&cityId=' + adminDetails.cityId : '',
      pageSize
    )
    navigate(routes.DASHBOARD_USERS)
  }

  const pressOnStationsMenu = () => {
    // props.getStations(0, '', '', savedCity || '', pageSize)
    refreshProps()
    navigate(routes.DASHBOARD_STATIONS)
  }

  const pressOnTransactionsMenu = () => {
    props.getPayment(0, '', '', savedCity || '', pageSize)
    refreshProps()
    navigate(routes.DASHBOARD_TRANSACTIONS)
  }

  const pressOnBikeMenu = () => {
    // props.getVehicle(0, '', '', savedCity || '', pageSize)
    props.getBikeTypes()
    refreshProps()
    navigate(routes.DASHBOARD_BIKES)
  }

  const pressOnDockMenu = () => {
    props.getDocks(0, '', '', savedCity || '', pageSize)
    refreshProps()
    getDockStatuses()
    navigate(routes.DASHBOARD_DOCKS)
  }

  const pressOnCitiesMenu = () => {
    refreshProps()
    props.getCities(0, '', '', '', pageSize)
    navigate(routes.DASHBOARD_CITIES)
  }

  const pressOnCouponsMenu = () => {
    refreshProps()
    props.getCities(0, '', '', '', pageSize)
    navigate(routes.DASHBOARD_CITIES)
  }

  const pressOnReferralsMenu = () => {
    refreshProps()
    props.getCities(0, '', '', '', pageSize)
    navigate(routes.DASHBOARD_CITIES)
  }

  const pressOnBookingsMenu = () => {
    refreshProps()
    props.getCities(0, '', '', '', pageSize)
    navigate(routes.BOOKING_ROUTE)
  }
  const pressOnNotificationsMenu = () => {
    refreshProps()
    navigate(routes.NOTIFICATIONS_ROUTE)
  }
  const pressOnSettingsMenu = () => {
    refreshProps()
    props.getCities(0, '', '', '', pageSize)
    navigate(routes.SETTINGS_ROUTE)
  }

  const pressOnRentalsMenu = () => {
    // props.getRentals(0, '', '', savedCity || '', pageSize)
    refreshProps()
    navigate(routes.DASHBOARD_RENTALS)
  }

  const pressOnRentalRulesMenu = () => {
    refreshProps()
    navigate(routes.DASHBOARD_RENTAL_RULES)
  }

  const changeSearch = ({ target }) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    setSearchValue(target.value)
  }

  const clearSearchValue = () => setSearchValue('')

  let pages
  if (isAdminAsset) {
    pages = [
      {
        path: routes.ASSET_PROJECTS,
        Component: AssetProjects,
      },
      {
        path: routes.ASSET_ASSETS,
        Component: AssetAssets,
      },
      {
        path: routes.ASSET_BOOKINGS,
        Component: AssetBookings,
      },
    ]
  } else {
    pages = [
      {
        path: routes.MFA_ROUTE,
        Component: MFAContainer,
      },
      {
        path: routes.DASHBOARD_USERS,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: ClientContainer,
      },
      {
        path: routes.DASHBOARD_BIKES,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          orderValue: orderValue,
          filterProps: filterProps,
        },
        Component: BikeContainer,
      },
      {
        path: routes.DASHBOARD_STATIONS,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: StationContainer,
      },
      {
        path: routes.DASHBOARD_DOCKS,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: DockContainer,
      },
      {
        path: routes.DASHBOARD_RENTALS,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          orderValue: orderValue,
          filterProps: filterProps,
        },
        Component: RentalsContainer,
      },
      {
        path: routes.DASHBOARD_RENTALS_GALLERY,
        Component: RentalsGallery,
      },
      {
        path: routes.DASHBOARD_RENTAL_RULES,
        Component: RentalRulesContainer,
      },
      {
        path: routes.DASHBOARD_TRANSACTIONS,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: TransactionContainer,
      },
      {
        path: routes.BIKE_HISTORY,
        Component: BikeHistoryContainer,
      },
      {
        path: routes.USER_LEDGER,
        Component: UserLedger,
      },
      {
        path: routes.COUPONS_ROUTE,
        Component: CouponsScreen,
      },
      {
        path: routes.USER_MANAGEMENT_ROUTE,
        Component: UserManagementScreen,
      },
      {
        path: routes.SERVICE_ACCOUNTS_ROUTE,
        Component: ServiceAccountsContainer,
      },
      {
        path: routes.REFERRAL_ROUTE,
        Component: ReferralsScreen,
      },
      {
        path: routes.PRICING_PLAN_EDITOR_ROUTE,
        Component: PricingPlanEditor,
      },
      {
        path: routes.PRICING_PLAN_EDITOR_CREATE_ROUTE,
        Component: PricingPlanEditor,
      },
      {
        path: routes.PRICING_PLAN_DOMAINS_ROUTE,
        Component: PricingPlanDomains,
      },
      {
        path: routes.USER_COUPON_ROUTE,
        Component: CouponUserScreen,
      },
      {
        path: routes.INTROS_ROUTE,
        Component: IntrosContainer,
      },
      {
        path: routes.WARNINGS_ROUTE,
        Component: WarningsContainer,
      },
      {
        path: routes.DASHBOARD_USER,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: ClientContainer,
      },
      {
        path: routes.DASHBOARD_BIKE,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: BikeContainer,
      },
      {
        path: routes.DASHBOARD_TRANSACTION,
        extraProps: {
          changeSearch: changeSearch,
          searchValue: searchValue,
          clearSearchValue: clearSearchValue,
          changePage: setCurrentPageValue,
          orderProps: orderProps,
          filterProps: filterProps,
        },
        Component: TransactionContainer,
      },
      {
        path: '',
        Component: MapContainerV2,
      },
    ]
  }

  return (
    <div>
      <Header cities={citiesList} city={props.city} />
      <div className="dashboard">
        {isAdminAsset && <AssetsMenu navigate={() => pressOnUserMenu()} />}

        {!isAdminAsset && (
          <div className="dashboardMenu">
            <DashboardItem
              name={strings('descriptions.dashboard')}
              icon={home}
              action={pressOnDashboard}
              isSelected={location.pathname === routes.DASHBOARD_SCREEN}
            />
            <DashboardItem
              name={strings('descriptions.bikes')}
              icon={bike}
              action={pressOnBikeMenu}
              isSelected={location.pathname === routes.DASHBOARD_BIKES}
              route={routes.DASHBOARD_BIKES}
            />
            <DashboardItem
              name={strings('descriptions.zones')}
              icon={station}
              action={pressOnStationsMenu}
              isSelected={location.pathname === routes.DASHBOARD_STATIONS}
              route={routes.DASHBOARD_STATIONS}
            />
            <DashboardItem
              name={strings('descriptions.docks')}
              icon={dock}
              action={pressOnDockMenu}
              isSelected={location.pathname === routes.DASHBOARD_DOCKS}
              route={routes.DASHBOARD_DOCKS}
            />
            <DashboardItem
              name={strings('descriptions.rentals')}
              icon={rentals}
              action={pressOnRentalsMenu}
              isSelected={location.pathname === routes.DASHBOARD_RENTALS}
              route={routes.DASHBOARD_RENTALS}
            />
            {isSuperAdminOrCityAdmin(adminDetails) && (
              <DashboardItem
                name={strings('descriptions.rentalRules')}
                IconComponent={RuleIcon}
                action={pressOnRentalRulesMenu}
                isSelected={location.pathname === routes.DASHBOARD_RENTAL_RULES}
                route={routes.DASHBOARD_RENTAL_RULES}
              />
            )}
            <DashboardItem
              name={strings('descriptions.transactions')}
              icon={transaction}
              action={pressOnTransactionsMenu}
              isSelected={location.pathname === routes.DASHBOARD_TRANSACTIONS}
              route={routes.DASHBOARD_TRANSACTIONS}
            />
            <DashboardItem
              name={strings('descriptions.users')}
              icon={user}
              action={pressOnUserMenu}
              isSelected={location.pathname === routes.DASHBOARD_USERS}
              route={routes.DASHBOARD_USERS}
            />

            <DashboardItem
              name={strings('descriptions.coupons')}
              icon={couponIcon}
              action={pressOnCouponsMenu}
              isSelected={location.pathname === routes.COUPONS_ROUTE}
              route={routes.COUPONS_ROUTE}
            />

            <DashboardItem
              name={strings('descriptions.referral')}
              icon={referralIcon}
              action={pressOnReferralsMenu}
              isSelected={location.pathname === routes.REFERRAL_ROUTE}
              route={routes.REFERRAL_ROUTE}
            />

            {isSuperAdminOrCityAdmin(adminDetails) && (
              <DashboardItem
                isHeaderItem={true}
                name={strings('descriptions.administration')}
                icon={admin}
              />
            )}

            {isSuperAdminOrCityAdmin(adminDetails) && (
              <DashboardItem
                name={strings('descriptions.cities')}
                icon={city}
                action={pressOnCitiesMenu}
                isSelected={location.pathname === routes.DASHBOARD_CITIES}
                route={routes.DASHBOARD_CITIES}
              />
            )}
            {isSuperAdmin(adminDetails) && (
              <DashboardItem
                name={'User Management'}
                icon={couponIcon}
                action={pressOnCouponsMenu}
                isSelected={location.pathname === routes.USER_MANAGEMENT_ROUTE}
                route={routes.USER_MANAGEMENT_ROUTE}
              />
            )}
            {isSuperAdmin(adminDetails) && (
              <DashboardItem
                name={'Service Accounts'}
                IconComponent={PointOfSaleIcon}
                action={() => {}}
                isSelected={location.pathname === routes.SERVICE_ACCOUNTS_ROUTE}
                route={routes.SERVICE_ACCOUNTS_ROUTE}
              />
            )}
            {isSuperAdminOrCityAdmin(adminDetails) && (
              <DashboardItem
                name={strings('descriptions.bookings')}
                icon={bookingsIcon}
                action={pressOnBookingsMenu}
                isSelected={location.pathname === routes.BOOKING_ROUTE}
                route={routes.BOOKING_ROUTE}
              />
            )}
            {/*{isSuperAdmin(adminDetails) && (*/}
            {/*  <DashboardItem*/}
            {/*    name={strings('descriptions.notifications')}*/}
            {/*    IconComponent={SendIcon}*/}
            {/*    action={pressOnNotificationsMenu}*/}
            {/*    isSelected={location.pathname === routes.NOTIFICATIONS_ROUTE}*/}
            {/*    route={routes.NOTIFICATIONS_ROUTE}*/}
            {/*  />*/}
            {/*)}*/}
            {isSuperAdmin(adminDetails) && (
              <DashboardItem
                name={strings('descriptions.settings')}
                icon={settingsIcon}
                action={pressOnSettingsMenu}
                isSelected={location.pathname === routes.SETTINGS_ROUTE}
                route={routes.SETTINGS_ROUTE}
              />
            )}
            <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
              {getCurrentVersion()}
            </p>
          </div>
        )}

        <div className={'dashboardContainer'}>
          <Routes>
            {pages.map(({ path, Component, extraProps }) => (
              <Route
                path={path.replace(routes.DASHBOARD_SCREEN, '')}
                key={path}
                element={<Component {...extraProps} />}
              />
            ))}
            {adminDetails?.cityId === null ? (
              <Route
                path={routes.DASHBOARD_CITIES.replace(routes.DASHBOARD_SCREEN, '')}
                element={
                  <CityContainer
                    changeSearch={changeSearch}
                    searchValue={searchValue}
                    clearSearchValue={clearSearchValue}
                    changePage={setCurrentPageValue}
                    orderProps={orderProps}
                    filterProps={filterProps}
                  />
                }
              />
            ) : null}
            {isSuperAdmin(adminDetails) && (
              <Route
                path={routes.NOTIFICATIONS_ROUTE.replace(routes.DASHBOARD_SCREEN, '')}
                element={<NotificationsContainer />}
              />
            )}
            {isSuperAdmin(adminDetails) && (
              <Route
                path={routes.SETTINGS_ROUTE.replace(routes.DASHBOARD_SCREEN, '')}
                element={<SettingsContainer />}
              />
            )}
            {isSuperAdminOrCityAdmin(adminDetails) && (
              <Route
                path={routes.BOOKING_ROUTE.replace(routes.DASHBOARD_SCREEN, '')}
                element={<BookingScreen />}
              />
            )}
            <Route path={'/dashboard/*'} element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  auth: { errorMessage, user, city, adminDetails },
  screen: {
    pageSize,
    searchValue,
    filterValue,
    currentPageValue,
    order,
    orderBy,
    typeFilter,
    stationTypeFilter,
    stationStateFilter,
    bikeStateFilter,
  },
  users: { removeUserSuccess },
  stations: { getStationsSuccess },
  vehicle: { getVehicleSuccess, bikesResult },
  locks: { getLocksSuccess },
  docks: { getDocksSuccess },
  cities: { getCitiesSuccess, citiesList, savedCity },
  rentals: { getRentalsSuccess },
}) => ({
  bikesResult,
  errorMessage,
  getStationsSuccess,
  getVehicleSuccess,
  getLocksSuccess,
  getDocksSuccess,
  getCitiesSuccess,
  citiesList,
  getRentalsSuccess,
  removeUserSuccess,
  user,
  city,
  savedCity,
  pageSize,
  searchValue,
  filterValue,
  currentPageValue,
  order,
  orderBy,
  typeFilter,
  stationTypeFilter,
  stationStateFilter,
  bikeStateFilter,
  adminDetails,
})

const mapDispatchToProps = {
  getUsers,
  getStations,
  getPayment,
  getVehicle,
  getLocks,
  getDocks,
  getCities,
  getRentals,
  clearErrorMessage,
  cityList,
  selectCity,
  setSearchValue,
  setFilterValue,
  setCurrentPageValue,
  setOrder,
  setOrderBy,
  getStationsOnMap,
  getBikeStatus,
  getAdminDetails,
  getDockStatuses,
  getBikeTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
